import React, { useState } from 'react';
import {
    selectUser,toggleFrontEndRelevance
} from './searchSlice';
import Modal from 'react-bootstrap/Modal'
import Users from './Users';
import { useAppSelector } from '../../app/hooks';
import { MegaMenu } from './MegaMenu'
import AutoSuggestMultiColumn from './AutoSuggestMultiColumn'
import { useAppDispatch } from '../../app/hooks';

export function Header() {
    const dispatch = useAppDispatch();
    const user: any = useAppSelector(selectUser);
    const firstName =
        (user != null && Object.keys(user).length > 0 && user.customer_name !== "Non Login") ? user.customer_name : "Signin";


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault()
        setShow(true)
    };

    const handleWishListClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        dispatch(toggleFrontEndRelevance())
    }

    return (
        <React.Fragment>
            <header className="header_area">
                <div className="header_top">
                    <div className="container">
                        <div className="top_inner">
                            <div className="row align-items-center">
                                <div className="col-lg-9 col-md-6">

                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <div className="top_right text-right">
                                        <ul>
                                            <li className="top_links"><a href="/" onClick={handleShow}><i className="ion-android-person"></i> Hello, {firstName} <i className="ion-ios-arrow-down"></i></a>
                                                <ul className="dropdown_links">
                                                    <li><a href="/" onClick={handleShow}>Switch User</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header_middle">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-md-6">
                                <div className="logo">
                                    <a href="/"><img src="/img/logo/logo_NewPig_UpgradeHeader2.png" alt="" /></a>
                                </div>
                                <p className="newpig-tagline">
                                    World’s best stuff for leaks, drips and spills.<sup>®</sup>
                                </p>
                            </div>
                            <div className="col-lg-9 col-md-6">
                                <div className="middel_right">
                                    <div className="search-container">
                                        <form action="#">
                                            <div className="search_box">
                                                <AutoSuggestMultiColumn />
                                            </div>
                                        </form>
                                    </div>
                                    <div className="middel_right_info">

                                        <div className="header_wishlist">
                                            <a href="wishlist.html" onClick={handleWishListClick}><span className="lnr lnr-heart"></span> Wish list </a>
                                            <span className="wishlist_quantity"></span>
                                        </div>
                                        <div className="mini_cart_wrapper">
                                            <a href="/"><span className="lnr lnr-cart"></span>My Cart </a>
                                            <span className="cart_quantity"></span>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mini_cart">
                    <div className="cart_close">
                        <div className="cart_text">
                            <h3>cart</h3>
                        </div>
                        <div className="mini_cart_close">
                            <a href="/"><i className="ion-android-close"></i></a>
                        </div>
                    </div>
                    <div className="cart_item">
                        <div className="cart_img">
                            <a href="/"><img src="img/s-product/product.jpg" alt="" /></a>
                        </div>
                        <div className="cart_info">
                            <a href="/">JBL Flip 3 Splasroof Portable Bluetooth 2</a>

                            <span className="quantity">Qty: 1</span>
                            <span className="price_cart">$60.00</span>

                        </div>
                        <div className="cart_remove">
                            <a href="/"><i className="ion-android-close"></i></a>
                        </div>
                    </div>
                    <div className="cart_item">
                        <div className="cart_img">
                            <a href="/"><img src="img/s-product/product2.jpg" alt="" /></a>
                        </div>
                        <div className="cart_info">
                            <a href="/">Koss Porta Pro On Ear Headphones </a>
                            <span className="quantity">Qty: 1</span>
                            <span className="price_cart">$69.00</span>
                        </div>
                        <div className="cart_remove">
                            <a href="/"><i className="ion-android-close"></i></a>
                        </div>
                    </div>
                    <div className="mini_cart_table">
                        <div className="cart_total">
                            <span>Sub total:</span>
                            <span className="price">$138.00</span>
                        </div>
                        <div className="cart_total mt-10">
                            <span>total:</span>
                            <span className="price">$138.00</span>
                        </div>
                    </div>

                    <div className="mini_cart_footer">
                        <div className="cart_button">
                            <a href="cart.html">View cart</a>
                        </div>
                        <div className="cart_button">
                            <a className="active" href="checkout.html">Checkout</a>
                        </div>

                    </div>

                </div>
                <MegaMenu />
                <Modal show={show} onHide={handleClose} size="xl" >
                    <Modal.Header closeButton>
                        <Modal.Title>Customer Selector</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Users closeModal={handleClose} />
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal>
            </header>

        </React.Fragment>
    )
}
export default Header