import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import {URLSync} from './features/search/URLSync'
import Header from "./features/search/Header";
import Footer from "./features/search/Footer";
import Browse from "./features/search/Browse";
import Home from "./features/search/Home";
import { Users } from "./features/search/Users";
import ProductDetailPage from "./features/search/ProductDetailPage";
import Search from "./features/search/Search";
import Content from "./features/search/Content";
import Relevance from "./features/search/predictive_merch/Relevance";
import Drawer from '@mui/material/Drawer';
import {
  selectFrontEnd
} from './features/search/searchSlice';
import { useAppSelector } from './app/hooks';
import { getCurrentEnvironment } from "./features/search/EnvironmentConfiguration"

function App() {
  const frontendState = useAppSelector(selectFrontEnd);
  const showRelevance = frontendState.relevance;
  console.log("Current Environment: " + getCurrentEnvironment());
  return (
    <>
    <Router>


  <main className="main-wrapper">
      <Header/>

      <div>
        <URLSync/>

        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/search">
              <Search/>
            </Route>
            <Route path="/content">
              <Content/>
            </Route>
            <Route path="/product/:id">
              <ProductDetailPage/>
            </Route>
            <Route path="/login">
              <Users />
            </Route>
            <Route path="/relevance">
              <Relevance />
            </Route>
            <Route exact path="/">
              <Home/>          
            </Route>
            <Route  path="/">
              <Browse/>
            </Route>
          </Switch>
      </div>
      
     <Footer/>

     <Drawer
            anchor='top'
            open={showRelevance}
            variant='persistent'
          >
           
            <Relevance/>
          </Drawer>
		</main>

  <script type="text/javascript" src="js/jquery.min.js"></script>
  <script type="text/javascript" src="js/bootstrap.min.js"></script>
  <script type="text/javascript" src="js/plugins.js"></script>  
  <script type="text/javascript" src="js/scripts.js"></script>
    
    </Router>
    </>
  );
}

export default App;



