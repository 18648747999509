import React, { useState } from 'react';


export function ItemDetailZone(props: any) {
  const solrResponse = props.zone.render;
  var docs = [];
  var groupId = "product_id";

  const [selectedVariantValue, setSelectedVariantValue] = useState("");


  if (solrResponse && solrResponse.grouped && solrResponse.grouped[groupId]) {
        docs = solrResponse.grouped[groupId].groups[0].doclist.docs;
  }
  else if (solrResponse && solrResponse.response && solrResponse.response.docs) {
      docs = solrResponse.response.docs;
  }

  const selectedDoc = (!selectedVariantValue) ? docs[0] : docs.filter((d:any)=>{return d.variant_qualifier_value === selectedVariantValue })[0];
  const productCode = selectedDoc.product_id;
  const imageUrl = "https://newpig.scene7.com/is/image/NewPig/PRD_" + productCode + "_ISET?wid=721&hei=722"
  const productTitle = selectedDoc.title;
  const shortDescription = selectedDoc.short_description;
  const variantValues = docs.map((doc:any) => doc.variant_qualifier_value)
  const variantName = selectedDoc.variant_qualifier;

  const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setSelectedVariantValue(e.target.value);
  }


  return (
    <React.Fragment>

    <div className="breadcrumbs_area">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="breadcrumb_content">
                        <ul>
                            <li><a href="/">home</a></li>
                            <li>product details</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="product_details mt-20">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-6">
                    <div className="product-details-tab">

                        <div id="img-1" className="zoomWrapper single-zoom">
                            <a href="/">
                                <img id="zoom1" src={imageUrl} alt="big-1"/>
                            </a>
                        </div>

                        <div className="single-zoom-thumb">
                            <ul className="s-tab-zoom owl-carousel single-product-active" id="gallery_01">
                                <li>
                                    <a href="/" className="elevatezoom-gallery active" data-update="" data-image="assets/img/product/product8.jpg" data-zoom-image="assets/img/product/product8.jpg">
                                        <img src="assets/img/product/product8.jpg" alt="zo-th-1" />
                                    </a>

                                </li>
                                <li>
                                    <a href="/" className="elevatezoom-gallery active" data-update="" data-image="assets/img/product/product9.jpg" data-zoom-image="assets/img/product/product9.jpg">
                                        <img src="assets/img/product/product9.jpg" alt="zo-th-1" />
                                    </a>

                                </li>
                                <li>
                                    <a href="/" className="elevatezoom-gallery active" data-update="" data-image="assets/img/product/product10.jpg" data-zoom-image="assets/img/product/product10.jpg">
                                        <img src="assets/img/product/product10.jpg" alt="zo-th-1" />
                                    </a>

                                </li>
                                <li>
                                    <a href="/" className="elevatezoom-gallery active" data-update="" data-image="assets/img/product/product13.jpg" data-zoom-image="assets/img/product/product13.jpg">
                                        <img src="assets/img/product/product13.jpg" alt="zo-th-1" />
                                    </a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6">
                    <div className="product_d_right">
                        <form action="#">

                            <h1>{productTitle}</h1>
                           <h1>{productCode}</h1>
                            {
                                <div className="price_box">
                                {
                                    (selectedDoc.price_effective && selectedDoc.price_effective !== selectedDoc.price) 
                                    ?  <React.Fragment>
                                    <span className="current_price">$ {selectedDoc.price_effective.toFixed(2)}</span>
                                    <span className="old_price">{selectedDoc.price.toFixed(2)}</span>
                                    </React.Fragment>
                                    :  <span className="current_price">$ {selectedDoc.price.toFixed(2)}</span>

                                }
                            </div>
                            
                            }
                            <div className="product_desc">
                                <p>{shortDescription}</p>
                            </div>
                            {
                                (docs.length === 1) 
                                ?<React.Fragment></React.Fragment>
                                :<div className="product_variant">
                                <h3>Available Options</h3>
                                <label>{variantName}</label>: 
                                <select onChange={handleInputChange} name="orderby" id="short" value={props.defaultValue}>
                                    {variantValues.map((opt: any) =>
                                        <option key={opt} value={opt}  >{opt}</option>
                                    )
                                    }
                                </select>
                            </div>
                            }
                            
                            <hr/>
                            <div className="product_variant quantity">
                                <label>quantity</label>
                                <input min="1" max="100" value="1" type="number"/>
                                <button className="button" type="submit">add to cart</button>

                            </div>
                            <div className=" product_d_action">
                                <ul>
                                    <li><a href="/" title="Add to wishlist">+ Add to Wishlist</a></li>
                                    <li><a href="/" title="Add to wishlist">+ Compare</a></li>
                                </ul>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </React.Fragment>
  )
}
export default ItemDetailZone