import React, { } from 'react';
import debounce from "lodash.debounce";
import AutoSuggestSelection from './AutoSuggestSelection'
import Popover from '@mui/material/Popover';
import axios from "axios";
import {
  Link
} from "react-router-dom";
import configData from "../../system_config.json"
import { getCurrentEnvironment } from "./EnvironmentConfiguration"


export function AutoSuggestMultiColumn() {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [searchTerm, setSearchTerm] = React.useState("default");
  const [suggestions, setSuggestions] = React.useState([]);
  const [queryToSearch, setQueryToSearch] = React.useState();
  const [trendingSearchesAndHistory, setTrendingSearchesAndHistory] = React.useState([]);
  const [skipTrendingSearchDisplay, setSkipTrendingSearchDisplay] = React.useState(false);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const debounceOnChange = React.useCallback(debounce(loadSuggestions, 250), []);


  function loadSuggestions(query, currentTarget) {
    //console.log("*******load suggestions called " + query);
    if (query === "disablepopup") {
      setAnchorEl(null);
    }
    else {
      if (query === "default" || query === "") {
        const urlStr = configData[getCurrentEnvironment()].trending_searches.url;
        const axiosParam = {
          url: urlStr,
          method: "get",
          auth: {
            username: configData[getCurrentEnvironment()].suggest.username,
            password: configData[getCurrentEnvironment()].suggest.password,
          },
        };
        axios(axiosParam)
          .then((res) => res.data)
          .then((result) => {
            setTrendingSearchesAndHistory(buildTrendingSearchesAndHistoryFromResult(result));
            //console.log("***************************************************inside  line 52");
            setAnchorEl(currentTarget);
          })
          .catch((err) => {
            console.error("Error occurred in querying Fusion: " + err.message);
          });
      }
      else {
  
        let params = new URLSearchParams();
        params.append("q", query);
  
        const urlStr = configData[getCurrentEnvironment()].suggest.url;
        const axiosParam = {
          url: urlStr,
          method: "get",
          params: params,
          auth: {
            username: configData[getCurrentEnvironment()].suggest.username,
            password: configData[getCurrentEnvironment()].suggest.password,
          },
        };
        axios(axiosParam)
          .then((res) => res.data)
          .then((result) => {
            const suggestionsFromResult = buildSuggestionsFromFusionResult(result);
            if(suggestionsFromResult.length > 0){
              setSuggestions(buildSuggestionsFromFusionResult(result));
              //console.log("inside  line 76 " + " " + JSON.stringify(suggestionsFromResult));
              setAnchorEl(currentTarget);  
            }
            else{
              setAnchorEl(null);
              //console.log("empty results, will not display type ahead popup");
            }
          })
          .catch((err) => {
            //console.error("Error occurred in querying Fusion: " + err.message);
          });
  
      }
    }
    
  }


  function buildSuggestionsFromFusionResult(result) {
    var suggestions = [];
    if (
      result &&
      result.grouped &&
      result.grouped.typeahead_type &&
      result.grouped.typeahead_type.groups
    ) {
      var groupList = result.grouped.typeahead_type.groups;
      groupList.forEach((group) => {
        const section = {
          title: group.groupValue,
          suggestions: group.doclist.docs,
        };
        suggestions.push(section);
      });
    }
    return suggestions;
  }

  function buildTrendingSearchesAndHistoryFromResult(result) {
    var suggestions = [];
    if (
      result && result.response && result.response.docs && result.response.docs.length > 0
    ) {
      result.response.docs.forEach((doc) => {
        suggestions.push(doc);
      });
    }
    return suggestions;
  }

  const handleTextFieldChange = (event) => {
    if (event.key === "Escape") {
      setAnchorEl(null);
    }
    else {
      setSearchTerm(event.target.value);
      //console.log("********calling debounce on line 133 " + event.target.value);
      debounceOnChange(event.target.value, event.currentTarget)
    }
  };

  const handleMouseClick = (event) => {
    //console.log("**** inside handle mouse click")
    if (event.target.value === "" && !skipTrendingSearchDisplay) {
      //console.log("********calling debounce on line 140 " + event.target.value + " skip value is " + String(skipTrendingSearchDisplay));
      debounceOnChange(event.target.value, event.currentTarget)
    }
    else if (event.target.value === "" && skipTrendingSearchDisplay) {
      console.log("Skipped a trending search display ")
      setSkipTrendingSearchDisplay(false);
    }
  };


  const handleOnKeyDown = (event) => {
    if (event.key === "Escape") {
      setAnchorEl(null);
    }
    if (event.keyCode === 13) {
      event.preventDefault();
      debounceOnChange("disablepopup", event.currentTarget)
      setQueryToSearch(event.target.value);
    }
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const suggestionsWIthoutProducts = suggestions.filter(g => g.title !== "product")
  const productSuggestions = suggestions.filter(g => g.title === "product")

  return (
    <React.Fragment>
      <input type="text" onKeyDown={handleOnKeyDown} onChange={handleTextFieldChange} onFocus={handleMouseClick} />
      <button type="submit" onClick={handleClick}><i className="ion-ios-search-strong"></i></button>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableAutoFocus={true}
        PaperProps={
          {
            style: { width: '800px' },
          }
        }
      >
        {
          (!skipTrendingSearchDisplay && (searchTerm === '' || searchTerm === 'default'))
            ? <TrendingSearches trendingSearchesAndHistory={trendingSearchesAndHistory} setQueryToSearch={setQueryToSearch} setAnchorEl={setAnchorEl} setSkipTrendingSearchDisplay={setSkipTrendingSearchDisplay} />
            : <TypeAheadSuggestions suggestionsWIthoutProducts={suggestionsWIthoutProducts} productSuggestions={productSuggestions} setQueryToSearch={setQueryToSearch} setAnchorEl={setAnchorEl} />
        }
      </Popover>


      <AutoSuggestSelection
        query={queryToSearch}
      />
    </React.Fragment>
  )
}

function TrendingSearches(props) {
  const trendingSearchesAndHistory = props.trendingSearchesAndHistory;
  const setQueryToSearch = props.setQueryToSearch;
  const setAnchorEl = props.setAnchorEl;
  const setSkipTrendingSearchDisplay = props.setSkipTrendingSearchDisplay;

  const handleClick = (searchTerm) => (event) => {
    event.preventDefault();
    //console.log("calling query to search: " + searchTerm);
    setSkipTrendingSearchDisplay(true);
    setQueryToSearch(searchTerm);
    setAnchorEl(null);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h4 className="autosuggest_group_title">Trending Searches</h4>
          <ul>
            {
              trendingSearchesAndHistory.map((suggestion) => {
                return (
                  <li key={suggestion.query}><a href="/" onClick={handleClick(suggestion.query)}>{suggestion.query}</a></li>
                )
              }
              )
            }
          </ul>
          <h4 className="autosuggest_group_title">Your Recent Searches</h4>
          <ul>
            <li>absorbent socks</li>
            <li>55 gallon drum</li>
            <li>battery acid spill kit</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

function TypeAheadSuggestions(props) {
  const suggestionsWIthoutProducts = props.suggestionsWIthoutProducts;
  const productSuggestions = props.productSuggestions;
  const setQueryToSearch = props.setQueryToSearch;
  const setAnchorEl = props.setAnchorEl;

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-3">
          {
            suggestionsWIthoutProducts.map((group) => {
              return (
                <React.Fragment key={group.title}>
                  <GroupTitle group_title={group.title} key={group.title}/>
                  <ul>
                    <GroupMemberSuggestions key={group.title} suggestions={group.suggestions} setQueryToSearch={setQueryToSearch} setAnchorEl={setAnchorEl} />
                  </ul>
                </React.Fragment>
              )
            })
          }
        </div>
        <div className="col-sm-9">
          {
            productSuggestions.map((group) => {
              return (
                <React.Fragment  key={group.title}>
                  <GroupTitle group_title={group.title} key={group.title} />
                  <ul>
                    <ProductGroupSuggestionGrid key={group.title} suggestions={group.suggestions} setQueryToSearch={setQueryToSearch} setAnchorEl={setAnchorEl} />
                  </ul>
                </React.Fragment>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

function GroupMemberSuggestions(props) {
  const suggestions = props.suggestions;
  const setQueryToSearch = props.setQueryToSearch;
  const setAnchorEl = props.setAnchorEl;

  const handleClick = (searchTerm) => (event) => {
    event.preventDefault();
    //console.log("calling query to search: " + searchTerm);
    setQueryToSearch(searchTerm);
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      {
        suggestions.map((suggestion) => {
          return (
            <li key={suggestion.name}><a href="/" onClick={handleClick(suggestion.name)}>{suggestion.name}</a></li>
          )
        }
        )
      }

    </React.Fragment>
  )
}

function ProductGroupSuggestionGrid(props) {
  const suggestions = props.suggestions;

  return (
    <React.Fragment>
      <div className="row">

        {
          suggestions.map((suggestion) => {
            return (
              <ProductSuggestion key={suggestion.id} suggestion={suggestion} />
            )
          }
          )
        }
      </div>
    </React.Fragment>
  )
}

function ProductSuggestion(props) {
  const doc = props.suggestion;
  const productCode = doc.product_id;
  const imageUrl = "https://newpig.scene7.com/is/image/NewPig/PRD_" + productCode + "_ISET?$PB04$";

  return (
    <div className="col-sm-4">
      <div className="single_product">
        <div className="product_name">
          <h4 ><Link to={'/product/' + doc.product_id}>{doc.product_id} </Link></h4>
          <h3>
            <Link to={'/product/' + doc.product_id}>
              {doc.title}
            </Link>
          </h3>
        </div>
        <div className="product_thumb">
          <Link className="primary_img" to={'/product/' + doc.product_id}><img src={imageUrl} alt="" /></Link>
          <Link className="secondary_img" to={'/product/' + doc.product_id}><img src={imageUrl} alt="" /></Link>
        </div>
        <div className="product_content">

          <div className="product_footer d-flex align-items-center">
            <div className="price_box">
              {
                (doc.price_effective && doc.price_effective !== doc.price)
                  ?
                  <React.Fragment>
                    <span className="current_price">$ {doc.price_effective.toFixed(2)}</span>
                    <span className="old_price">{doc.price.toFixed(2)}</span>
                  </React.Fragment>
                  :
                  <span className="current_price">$ {doc.price && doc.price.toFixed(2)}</span>
              }

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


function GroupTitle(props) {
  const group_title = props.group_title;
  const autosuggest_title_map = {
    popular_searches: "Popular Searches",
    category: "Category",
    brand: "Brand",
    product: "Products"
  }

  const label = (autosuggest_title_map[group_title]) ? autosuggest_title_map[group_title] : group_title
  return (
    <h4 className="autosuggest_group_title">{label}</h4>
  )
}

export default AutoSuggestMultiColumn