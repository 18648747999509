import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import searchReducer from '../features/search/searchSlice';
import { FusionEndpoint } from '../features/search/FusionAPI';
import { RulesAPI } from '../features/search/RulesAPI';

export const store = configureStore({
  reducer: {
    search: searchReducer,
    [FusionEndpoint.reducerPath]: FusionEndpoint.reducer,
    [RulesAPI.reducerPath]: RulesAPI.reducer
  },
    // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
    .concat(FusionEndpoint.middleware)
    .concat(RulesAPI.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
